import * as React from 'react'
import Breadcrumb from '../Breadcrumb'
import { Descriptions, Row, Col, Table, Modal, Card, Tag, Button, Upload, message } from 'antd'
import ApplyStuEdit from './ApplyStuEdit'
import { IMAGE_SERVER } from '../../service/config'
import { orderOne, orderStuAdd, orderStuDel, orderCancel } from './../../service/index'
import { RouteComponentProps } from 'react-router-dom'
import OrderStuAdd from './OrderStuAdd'
import { Link } from 'react-router-dom'
const { Column } = Table
const { confirm } = Modal

export interface IOrderDetailProps extends RouteComponentProps<any> {}
interface IOrderDetailStates {
  orderId: any
  order: any
  loading: boolean
  applyStuList: any[]
  fileList: any[]
  previewVisible: boolean
  previewImage: any
  source: any
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default class OrderDetail extends React.Component<IOrderDetailProps, IOrderDetailStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      orderId: props.query.orderId,
      source: props.query.source,
      order: {},
      loading: false,
      applyStuList: [],
      fileList: [],
      previewVisible: false,
      previewImage: '',
    }
  }
  componentDidMount() {
    this.getOrder()
  }

  getOrder = async () => {
    if (this.state.orderId) {
      this.setState({ loading: true })
      const res = await orderOne(this.state.orderId)
      if (res.msgStatsNum === 10) {
        message.warn('您还未登录！')
        return
      }
      if (res.success) {
        const order = res.data[0]
        if (order) {
          const orderPayJson = order.orderPayJson
          if (orderPayJson && orderPayJson.offlineJson) {
            const offlineJson = orderPayJson.offlineJson
            const fileList = {
              uid: offlineJson.payOffId,
              name: offlineJson.origFileName,
              status: 'done',
              url: IMAGE_SERVER + offlineJson.textDirectURL,
            }
            this.setState({ order: order, applyStuList: order.orderStuJson, fileList: [fileList] })
          }
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  /**
   * 取消订单
   * @param orderId
   */
  async cancelOrder() {
    const params = {
      orderId: this.state.orderId,
    }
    const res = await orderCancel(params)
    if (res.success) {
      message.success('取消成功')
      setTimeout(() => {
        this.getOrder()
      }, 0)
    } else {
      message.success('取消失败')
    }
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }

  handleChange = (e: any) => this.setState({ fileList: e.fileList })

  public render() {
    let orderStatusStr = ''
    let tagColor = ''
    const { order } = this.state

    if (order) {
      if (order.orderStatus === 1) {
        orderStatusStr = '待审核'
        tagColor = 'orange'
      } else if (order.orderStatus === 2) {
        orderStatusStr = '审核通过'
        tagColor = 'green'
      } else if (order.orderStatus === 3) {
        orderStatusStr = '审核不通过'
        tagColor = 'red'
      } else if (order.orderStatus === 4) {
        orderStatusStr = '已取消'
        tagColor = 'gray'
      } else {
        orderStatusStr = ''
      }
    }

    let status = <span></span>
    let opera = <div></div>
    switch (order.orderStatus) {
      case 1:
        status = <span className="text-yellow">审核中</span>
        switch (order.payStatus) {
          case 1:
            status = <span className="text-yellow">未支付</span>
            opera = (
              <div>
                <Link to={'/app/train/orderPay?orderId=' + order.orderId} color="gray">
                  <Button type="primary">去支付</Button>
                </Link>
                <Button
                  type="danger"
                  className="ml-s"
                  ghost
                  onClick={() => {
                    const self = this
                    confirm({
                      title: '确定要取消订单吗?',
                      onOk() {
                        self.cancelOrder()
                      },
                      onCancel() {
                        console.log('Cancel')
                      },
                    })
                  }}
                >
                  取消订单
                </Button>
              </div>
            )
            break
          case 2:
            status = <span className="text-yellow">审核中</span>
            break
          case 3:
            status = <span className="text-red">已取消</span>
            break
        }
        break
      case 2:
        status = <div className="text-green">审核通过</div>
        let url = ''
        if (order.orderType == 1) {
          url = '/app/train/course?id='
        } else if (order.orderType == 2) {
          url = '/app/train/train?id='
        } else if (order.orderType == 3) {
          url = '/app/train/plan?id='
        }
        opera = (
          <Link to={url + order.orderTypeId} color="gray">
            <Button className="ml-m" type="primary">
              开始学习
            </Button>
          </Link>
        )
        break
      case 3:
        status = <span className="text-red">审核不通过</span>
        break
      case 4:
        status = <span className="text-gray">已取消</span>
        break
      default:
        break
    }

    return (
      <div>
        <div className="app_layout_main">
          <Breadcrumb />
          <Card>
            <Row>
              <Col span={11}>
                <Descriptions size="small" title="订单信息" column={2} bordered>
                  <Descriptions.Item label="订单号" span={2}>
                    {this.state.order.orderNum}
                  </Descriptions.Item>
                  <Descriptions.Item label="培训项目" span={2}>
                    {this.state.order.orderTitle}
                  </Descriptions.Item>
                  <Descriptions.Item label="报名人数">
                    {this.state.order.enrollCount}人
                  </Descriptions.Item>
                  <Descriptions.Item label="报名时间">{this.state.order.regtime}</Descriptions.Item>
                  <Descriptions.Item label="联络人">
                    {this.state.order.linkPerson}
                  </Descriptions.Item>
                  <Descriptions.Item label="手机号码">
                    {this.state.order.linkPhone}
                  </Descriptions.Item>
                  <Descriptions.Item label="单价">
                    {this.state.order.unitPrice || 0}元
                  </Descriptions.Item>
                  <Descriptions.Item label="总金额">
                    {this.state.order.totalAmount || 0}元
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="待支付金额">
                  {this.state.order.price || 0}元
                </Descriptions.Item> */}
                  <Descriptions.Item label="状态">
                    <Tag color={tagColor}>{orderStatusStr}</Tag>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={11} offset={1}>
                <div className="ant-descriptions-title">付款凭证</div>
                <Upload
                  listType="picture-card"
                  fileList={this.state.fileList}
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}
                  disabled
                ></Upload>
                <Modal
                  visible={this.state.previewVisible}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
              </Col>
            </Row>
            <div>
              <div className="ant-descriptions-title mt-s">学员信息</div>
              <Table
                className="mt-m"
                rowKey="itemId"
                size="small"
                loading={this.state.loading}
                dataSource={this.state.applyStuList}
              >
                <Column
                  title="序号"
                  dataIndex="name"
                  key="name"
                  render={(name: any, row: any, index) => index + 1}
                />
                <Column
                  title="姓名"
                  dataIndex="stuName"
                  key="stuName"
                  render={(stuName: any) => stuName}
                />
                <Column
                  title="单位名称"
                  dataIndex="compName"
                  key="compName"
                  render={(compName: any) => compName}
                />
                <Column title="电话" dataIndex="phone" key="phone" render={(phone: any) => phone} />
                {this.state.order.orderStatus === 2 && (
                  <Column
                    title="操作"
                    dataIndex="itemId"
                    key="itemId"
                    render={(itemId: any, row: any, index: number) => (
                      <div className="flex">
                        <ApplyStuEdit
                          type="edit"
                          item={row}
                          onCourseChange={async (stu: any) => {
                            const params = {
                              orderId: this.state.orderId,
                              orderStuJson: JSON.stringify([stu]),
                            }
                            const res = await orderStuAdd(params)
                            if (res.success) {
                              this.getOrder()
                            } else {
                              message.error(res.msg)
                            }
                            // const applyStuList: any[] = this.state.applyStuList
                            // const oldStu = applyStuList[index]
                            // applyStuList[index] = { ...oldStu, ...stu }
                            // this.setState({
                            //   applyStuList: applyStuList,
                            // })
                          }}
                        />
                        <Button
                          type="link"
                          color="red"
                          onClick={async () => {
                            const res = await orderStuDel(row.orderStuId)
                            if (res.success) {
                              this.getOrder()
                            } else {
                              message.error(res.msg)
                            }

                            // const { applyStuList } = this.state
                            // applyStuList.splice(index, 1)
                            // this.setState({
                            //   applyStuList: applyStuList,
                            // })
                          }}
                        >
                          删除
                        </Button>
                      </div>
                    )}
                  />
                )}
              </Table>
            </div>

            <div className="flex-vcenter mt-m">
              {status}
              {this.state.order.orderStatus === 2 && (
                <div className="ml-m">
                  <OrderStuAdd
                    btnType="primary"
                    order={this.state.order}
                    onSuccess={() => {
                      this.getOrder()
                    }}
                  />
                </div>
              )}
              <span className="ml-m">{opera}</span>
            </div>
          </Card>
        </div>
      </div>
    )
  }
}
