/**
 * Created by 叶子 on 2017/7/30.
 * 接口地址配置文件
 */

// export const SERVER_URL = 'http://172.16.0.84:58888/train/'
// export const IMAGE_SERVER = 'http://172.16.0.84:58888/train/'
// export const BASE_URL = '/train/'

// export const SERVER_URL = 'http://115.238.109.94:58888/train'
// export const IMAGE_SERVER = 'http://115.238.109.94:58888/train/'
// export const BASE_URL = '/train/'

// export const SERVER_URL = 'http://whentrain.wazert.com/prod-api/'
// export const IMAGE_SERVER = 'http://whentrain.wazert.com/prod-api/'
// export const BASE_URL = '/prod-api/'

// export const SERVER_URL = 'http://xzzpx.cn/prod-api/'
// export const IMAGE_SERVER = 'http://xzzpx.cn/prod-api/'
// export const BASE_URL = '/prod-api/'

export const SERVER_URL = 'http://qbspx.wazert.com/prod-api/'
export const IMAGE_SERVER = 'http://qbspx.wazert.com/prod-api/'
export const BASE_URL = '/prod-api/'

// export const SERVER_URL = 'http://wqpy.wazert.com/prod-api/'
// export const IMAGE_SERVER = 'http://wqpy.wazert.com/prod-api/'
// export const BASE_URL = '/prod-api/'

// export const SERVER_URL = 'http://sxhpx.wazert.com/prod-api/'
// export const IMAGE_SERVER = 'http://sxhpx.wazert.com/prod-api/'
// export const BASE_URL = '/prod-api/'

export const GJFW_BASE_URL = '/gjhntfw/'


export const GJFU_MSG_PUSH = GJFW_BASE_URL + 'message/push'

export const LOGIN = BASE_URL + 'accountcs/veriFAppl'
export const REGISTER = BASE_URL + 'accountcs/regStu'
export const SMS_CODE = BASE_URL + 'accountcs/sendSmsCode'
export const VALID_CODE = BASE_URL + 'accountcs/valiCodeFClouBase64'
export const LOGIN_BY_SESSIONID = BASE_URL + 'accountcs/getUserByRsessionid?gSessionID='

export const HOME_RECOMMEND = BASE_URL + 'platf0indexcs/recommend'
export const PLAN_LIST = BASE_URL + 'platf0indexcs/planList'
export const TRAIN_LIST = BASE_URL + 'platf0indexcs/itemList'
export const COURSE_LIST = BASE_URL + 'platf0indexcs/courseList'
export const MY_LEARN_LIST = BASE_URL + 'platf0leacs/learnCenter'
export const COURSE_ONE = BASE_URL + 'platf0indexcs/courseOne'
export const PLAN_ONE = BASE_URL + 'platf0indexcs/planOne'
export const TRAIN_ONE = BASE_URL + 'platf0indexcs/itemOne'
export const ORDER_LIST = BASE_URL + 'appl0ordercs/myOrderList'
export const USER_DETAIL = BASE_URL + 'appl0infocs/personalCenter'
export const CREATE_ORDER = BASE_URL + 'platf0indexcs/orderAddMod'
export const TRAIN_TYPE = BASE_URL + 'platf0itemcs/itemTypeList'
export const ORDER_CANCEL = BASE_URL + 'platf0ordercs/orderCancel'
export const ORDER_ONE = BASE_URL + 'platf0ordercs/orderOne'
export const ORDER_STU_ADD = BASE_URL + 'platf0ordercs/orderStuAddMod'
export const ORDER_STU_DEL = BASE_URL + 'platf0ordercs/orderStuDele'
export const ORDER_STU_IMP = BASE_URL + 'platf0ordercs/orderStuImpExce'
export const FAVOR_ITEM = BASE_URL + 'appl0infocs/favoriteAdd'
export const FAVOR_ITEM_DEL = BASE_URL + 'appl0infocs/favoriteDele'
export const FAVOR_LIST = BASE_URL + 'appl0infocs/favoriteList'
export const PAY = BASE_URL + 'appl0paycs/applyPrePayWeb'
export const QR_PAY = BASE_URL + 'appl0wzpaycs/applyPrePayWeb'
export const HOME_ADV_LIST = BASE_URL + 'platf0indexcs/advertListAndSet'
export const HOME_REC_LIST = BASE_URL + 'platf0indexcs/recommendList'
export const CATEGORY_LIST = BASE_URL + 'platf0indexcs/categoryList'
export const EXPERT_LIST = BASE_URL + 'platf0indexcs/expertList'
export const EXPERT_ONE = BASE_URL + 'platf0indexcs/expertOne'
export const CATEGORY_MORE = BASE_URL + 'platf0indexcs/categoryMore'
export const CHANNEL_LIST = BASE_URL + 'platf0indexcs/channelList'
export const CHANNEL_ONE = BASE_URL + 'platf0indexcs/channelOne'
export const COMP_LIST = BASE_URL + 'platf0infcs/compList'
export const COMPLETE_STU = BASE_URL + 'accountcs/completeStu'
export const GLOBAL_REGION = BASE_URL + 'communalcs/pcc'
export const ORDER_PAY_OFFLINE = BASE_URL + 'appl0ordercs/orderPayOffline'
export const MOD_PWD = BASE_URL + 'appl0infocs/pwdMod'
export const CREATE_ORDER_AUTO = BASE_URL + 'appl0ordercs/orderAddLearnCour'
export const CHAPTER_TEXT_PROGRESS_UPDATE = BASE_URL + 'appl0coucs/chapTextStuAddModWeb'
export const CHAPTER_TEXT_PROGRESS_FINISHED = BASE_URL + 'appl0coucs/chapTextStuAddMod'
export const MY_INVOICE = BASE_URL + 'appl0ordercs/myInvoiceList'
export const INVOICE_ADD = BASE_URL + 'appl0ordercs/invoiceAddMod'
export const INVOICE_ONE = BASE_URL + 'appl0ordercs/invoiceOne'
export const INVOICE_COMP_ADD = BASE_URL + 'appl0ordercs/invoiceCompAdd'
export const INVOICE_COMP = BASE_URL + 'appl0ordercs/myInvoiceCompList'
